import styles from './NotFound.module.scss';
import Image from 'next/image';
import Button from '@glise/components/common/Button/Button';
import ArrowIcon from '@glise/icons/ArrowIcon';
import Link from 'next/link';
import { CLOUDFRONT_URL, currentHost } from '@glise/utils/constants';
import Container from '@mui/material/Container';
import useTranslation from 'next-translate/useTranslation';

function NotFoundComponent() {
  const { t } = useTranslation('not-found');
  return (
    <div className={styles.NotFoundContainer} id="NotFound">
      <Container>
        <div className={styles.NotFoundWrapper}>
          <div className={styles.NotFoundTextContainer}>
            <div className={styles.BigText}>{t('title')}</div>
            <div className={styles.SmallText}>{t('subtitle')}</div>
            <div className={styles.ButtonsWrapper}>
              <Link href="/" aria-label={t('actions.home')}>
                <Button
                  className={styles.ButtonHome}
                  color="transparent"
                  aria-label={t('actions.home')}
                >
                  {t('actions.home')}
                </Button>
              </Link>
              <Link
                href={`https://app.${currentHost}/login?getStarted=free`}
                aria-label={t('actions.signIn')}
              >
                <Button
                  className={styles.Button}
                  color="blue"
                  endIcon={<ArrowIcon />}
                  aria-label={t('actions.signIn')}
                >
                  {t('actions.signIn')}
                </Button>
              </Link>
            </div>
          </div>
          <div className={styles.NotFoundLogoContainer}>
            <Image
              src={`${CLOUDFRONT_URL}FullLogotype.webp`}
              width={519}
              height={519}
              crossOrigin={'anonymous'}
              layout="responsive"
              alt="Glise"
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default NotFoundComponent;
