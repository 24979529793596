
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import NotFoundComponent from '@glise/bricks/NotFound/NotFound';
import Head from 'next/head';
import useTranslation from 'next-translate/useTranslation';
function NotFound() {
    const { t } = useTranslation('metainfo');
    return (<>
      <Head>
        <title>{t('notFound.title')}</title>
      </Head>
      <NotFoundComponent />
    </>);
}
export default NotFound;

    async function __Next_Translate__getStaticProps__194f03b57cc__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194f03b57cc__ as getStaticProps }
  